/**
 * 整数部分添加千分撇
 *
 * @param  {[type]} num [description]
 * @return {[type]}     [description]
 */
export function formatThousandth(num) {
    num = num + "";
    var priceDecimalArr = num.split('.');
    var intPart = priceDecimalArr[0] || "";
    var decimalPart = priceDecimalArr[1] || "";

    var parts = [];

    if (intPart) {
        var i = intPart.length;
        while (i > 0) {
            i = i - 3;
            var item = ''
            if (i <= 0) {
                item = intPart.substr(0, i + 3);
            } else {
                item = intPart.substr(i, 3);
            }
            parts.unshift(item);
        }
    }

    var intResult = parts.join(',');

    if (decimalPart) {
        return intResult + '.' + decimalPart
    } else {
        return intResult;
    }
}