import React, { useEffect, useState, useRef } from "react";
import intl from "react-intl-universal";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow, Autoplay } from "swiper";
import http from "axios";

import "swiper/swiper.scss";
import "swiper/components/effect-coverflow/effect-coverflow.scss";
import "./index.scss";
import "./m.index.scss";

import logo from "@/static/img/index/logo.png";
import footerLogo from "@/static/img/index/footer-logo.png";
import bannerLogo from "@/static/img/index/banner-logo.png";
import telegram from "@/static/img/index/telegram.png";
import bep20 from "@/static/img/index/bep.png";
import erc20 from "@/static/img/index/erc20.png";
import trc20 from "@/static/img/index/trc20.png";
import ercTokenInfo from "@/static/img/index/token-info.png";
import trcTokenInfo from "@/static/img/index/trctoken-info.png";
import contractInfo from "@/static/img/index/contract-info.png";
import map from "@/static/img/index/map.png";
import payApi from "@/static/img/index/pay-api.png";
import check from "@/static/img/index/check.png";
import pdf from "@/static/img/index/pdf.png";
import githubInfo from "@/static/img/index/github-info.png";
import circulation from "@/static/img/index/circulation.png";
import exchangeNum from "@/static/img/index/exchange-num.png";
import redemption from "@/static/img/index/redemption.png";
import bannerBg from "@/static/img/index/banner-background.png";
import wallet from "@/static/img/index/wallet.png";
import market from "@/static/img/index/market.png";
import select from "@/static/img/index/select.png";
import purchase from "@/static/img/index/purchase.png";
import freedom from "@/static/img/index/freedom.png";
import trans from "@/static/img/index/trans.png";
import opacityPane from "@/static/img/index/opacity-pane.png";
import opacityC from "@/static/img/index/opacity-c.png";
import stablePane from "@/static/img/index/stable-pane.png";
import stableC from "@/static/img/index/stable-c.png";
import crediblePane from "@/static/img/index/credible-pane.png";
import credibleC from "@/static/img/index/credible-c.png";
import zhCN from "@/static/img/index/zh-CN.png";
import zhTW from "@/static/img/index/zh-TW.png";
import enUS from "@/static/img/index/en-US.png";
import bannerCoinB from "@/static/img/index/banner-coin-b.png";
import bannerCoinEth from "@/static/img/index/banner-coin-eth.png";
import bannerCoinGreen from "@/static/img/index/banner-coin-green.png";
import bannerCoinPurple from "@/static/img/index/banner-coin-purple.png";
import bannerCoinT from "@/static/img/index/banner-coin-t.png";
import bannerCoinYellow from "@/static/img/index/banner-coin-yellow.png";
import sliderL from "@/static/img/index/slider-l.png";
import sliderR from "@/static/img/index/slider-r.png";
import globalCny from "@/static/img/index/global-cny.png";

import { isInView } from "@/utils/el.js";
import { formatThousandth } from "@/utils/digital.js";

SwiperCore.use([EffectCoverflow, Autoplay]);
const SUPPOER_LOCALES = [
  {
    name: "简体",
    value: "zh-CN",
    img: zhCN,
  },
  {
    name: "繁體",
    value: "zh-TW",
    img: zhTW,
  },
  {
    name: "English",
    value: "en-US",
    img: enUS,
  },
];
const LANGMAP = {
  "zh-CN": zhCN,
  "zh-TW": zhTW,
  "en-US": enUS,
};
const SUPPOER_LOCALESMAP = {
  "zh-CN": {
    name: "简体",
    value: "zh-CN",
    img: zhCN,
  },
  "zh-TW": {
    name: "繁體",
    value: "zh-TW",
    img: zhTW,
  },
  "en-US": {
    name: "English",
    value: "en-US",
    img: enUS,
  },
};
export default function () {
  const [initDone, setInitDone] = useState(false);
  const [winWidth, setWinWidth] = useState(() => {
    return window.$(window).width();
  });

  useEffect(() => {
    window.$(window).resize(function () {
      setWinWidth(window.$(window).width());
    });
    loadLocales();
  }, []);

  function loadLocales() {
    let currentLocale = intl.determineLocale({
      urlLocaleKey: "lang",
      cookieLocaleKey: "lang",
    });
    const isContain = SUPPOER_LOCALES.find(item => {
      if (item.value == currentLocale) {
        return true;
      }
    });
    if (!isContain) {
      currentLocale = "zh-CN";
    }
    http
      .get(`locales/${currentLocale}.json`)
      .then(res => {
        return intl.init({
          currentLocale,
          locales: {
            [currentLocale]: res.data,
          },
        });
      })
      .then(() => {
        setInitDone(true);
        document.title = intl.get("title");
      });
    window.$("body").addClass(currentLocale);
    window.localStorage.setItem("lang", currentLocale);
  }
  return (
    <div className="page-index">
      {initDone ? (
        <React.Fragment>
          <Header />
          <Banner />
          <Digital />
          {/* <WhyChoice /> */}
          {/* <StableValue /> */}
          {/* <Credible /> */}
          {winWidth > 750 ? <Advantage /> : <MAdvantage />}
          <Business />
          <GlobalCny />
          <TokenInfo />
          <Footer />
        </React.Fragment>
      ) : (
        ""
      )}
    </div>
  );
}
function Header() {
  useEffect(() => {
    window.$(".lang-wrap").click(e => {
      e.stopPropagation();
      window.$(".muti-lang ul").slideToggle("fast");
    });
    window.$("body").click(() => {
      window.$(".muti-lang ul").slideUp("fast");
    });
  }, []);

  function onChoiceLang(item, e) {
    window.$(".muti-lang ul").slideUp("fast");
    setTimeout(() => {
      window.location.search = `?lang=${item.value}`;
    }, 200);
  }
  return (
    <div className="header">
      <div className="header-wrap common-index">
        <div className="menu-wrap">
          <div className="logo">
            <a href={`/?lang=${intl.options.currentLocale}`}>
              <img src={logo} alt="" />
            </a>
          </div>
        </div>
        <div className="muti-lang">
          <div className="lang-wrap">
            <img
              className="lang-icon"
              src={LANGMAP[intl.options.currentLocale]}
              alt=""
            />
            <span>{intl.get("lang-type")}</span>
            <img className="lang-select-icon" src={select} alt="" />
          </div>
          <ul>
            {SUPPOER_LOCALES.map(item => {
              return (
                <li
                  key={item.value}
                  onClick={() => {
                    onChoiceLang(item);
                  }}
                >
                  <img src={item.img} alt="" />
                  <span>{item.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
function Banner() {
  let [textList, setTextList] = useState([]);
  const [lang, setLang] = useState("zh-CN");
  const logoRef = useRef();
  const bg = {
    backgroundImage: `url(${bannerBg})`,
  };
  useEffect(() => {
    const fontList = intl.get("USDB-稳定数字美元").split("");
    let size = 0;

    setTimeout(() => {
      const timer = setInterval(() => {
        size++;
        const temp = [...fontList];
        temp.length = size;
        setTextList(temp);
        if (size === fontList.length) {
          clearInterval(timer);
        }
      }, 100);
    }, 400);
  }, []);
  useEffect(() => {
    window.$(".aniview").AniView({
      animateThreshold: 50,
    });
    setLang(localStorage.getItem("lang"));
  }, []);

  return (
    <div className="banner" style={bg}>
      <div className="banner-content common-index">
        <div className="tip-wrap">
          <h1 className="alibaba-puhuitib-1">
            {textList.map(word => {
              return word;
            })}
          </h1>
          <h2 className="alibaba-puhuitib my-fadeInDown">
            {intl.get("匿名 、安全")}
          </h2>
          <h2 className="alibaba-puhuitib my-fadeInDown">
            {intl.get("价值恒定 、全球兑换")}
          </h2>
          <div className="white-paper-btn">
            {lang == "en-US" ? (
              <a target="_blank" href="https://usdb.pro/2.pdf">
                {intl.get("查看白皮书")}
              </a>
            ) : (
              <a target="_blank" href="https://usdb.pro/1.pdf">
                {intl.get("查看白皮书")}
              </a>
            )}
          </div>
        </div>
        <div className="big-logo" ref={logoRef}>
          <img className="banner-logo" src={bannerLogo} alt="" />
          <img className="banner-coin-b" src={bannerCoinB} alt="" />
          <img className="banner-coin-eth" src={bannerCoinEth} alt="" />
          <img className="banner-coin-green" src={bannerCoinGreen} alt="" />
          <img className="banner-coin-purple" src={bannerCoinPurple} alt="" />
          <img className="banner-coin-t" src={bannerCoinT} alt="" />
          <img className="banner-coin-yellow" src={bannerCoinYellow} alt="" />
        </div>
      </div>
    </div>
  );
}
const flagOption = {
  flag1: false,
  flag2: false,
  flag3: false,
};
function Digital() {
  const [currDate, setCurrDate] = useState("--");
  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);
  const [number3, setNumber3] = useState(0);
  useEffect(() => {
    window.$("#digital-box").mouseleave(function () {
      window.$("#exchange-num").addClass("center-box");
    });
    window.$("#digital-box li").mouseenter(function () {
      window.$("#exchange-num").removeClass("center-box");
      window.$(this).addClass("center-box");
    });
    window.$("#digital-box li").mouseleave(function () {
      window.$(this).removeClass("center-box");
    });

    // setInterval(() => {
    //   const date = new Date();
    //   const formatDate = window.DateFormat.format.date(
    //     date,
    //     "yyyy/MM/dd HH:mm:ss"
    //   );
    //   setCurrDate(formatDate);
    // }, 1000);

    http.get(`/stat.json?${new Date().getTime()}`).then(res => {
      const { refrash, CirculatingSupply, Redemption, TotalSupply } = res.data;
      setCurrDate(refrash);
      init({
        el: "digital-num1",
        start: 0,
        end: CirculatingSupply,
        fn: setNumber1,
        flag: "flag1",
      });

      init({
        el: "digital-num2",
        start: 0,
        end: TotalSupply,
        fn: setNumber2,
        flag: "flag2",
      });
      init({
        el: "digital-num3",
        start: 0,
        end: Redemption,
        fn: setNumber3,
        flag: "flag3",
      });

      window.$(window).scroll(function () {
        if (!flagOption.flag1) {
          init({
            el: "digital-num1",
            start: 100,
            end: CirculatingSupply,
            fn: setNumber1,
            flag: "flag1",
          });
        }
        if (!flagOption.flag2) {
          init({
            el: "digital-num2",
            start: 100,
            end: TotalSupply,
            fn: setNumber2,
            flag: "flag2",
          });
        }

        if (!flagOption.flag3) {
          init({
            el: "digital-num3",
            start: 0,
            end: Redemption,
            fn: setNumber3,
            flag: "flag3",
          });
        }
      });
    });
  }, []);

  function init({ el, start, end, fn, flag }) {
    const isView = isInView(document.getElementById(el));
    const bit = (end - start) / 45;
    let result = start;
    if (isView) {
      flagOption[flag] = true;
      const timer = setInterval(() => {
        result += bit;
        result = Math.floor(result);
        if (end - result < 1.5 * bit) {
          result = end;
          clearInterval(timer);
        }
        const showNum = formatThousandth(result);
        fn(showNum);
      }, 34);
    }
  }
  return (
    <div className="digital common-index">
      <div className="common-title alibaba-puhuitib">
        {intl.get("最新数据")}
      </div>
      <div className="update-time">
        {intl.get("数据更新时间：")}
        {currDate}
      </div>
      <ul id="digital-box">
        <li>
          <img src={circulation} alt="" />
          <div className="digital-m-circulation">
            {/* <p id="digital-num1" className="alibaba-puhuitib">¥132,010,732</p> */}
            <p id="digital-num1" className="alibaba-puhuitib">
              ${number1}
            </p>
            <span>{intl.get("当前流通量")}</span>
          </div>
        </li>
        <li id="exchange-num" className="center-box">
          <img src={exchangeNum} alt="" />
          <div className="digital-m-circulation">
            {/* <p id="digital-num2" className="alibaba-puhuitib">¥200,000,000</p> */}
            <p id="digital-num2" className="alibaba-puhuitib">
              ${number2}
            </p>
            <span>{intl.get("累计发行量")}</span>
          </div>
        </li>
        <li>
          <img src={redemption} alt="" />
          <div className="digital-m-circulation">
            {/* <p id="digital-num3" className="alibaba-puhuitib">¥0</p> */}
            <p id="digital-num3" className="alibaba-puhuitib">
              ${number3}
            </p>
            <span>{intl.get("累计赎回量")}</span>
          </div>
        </li>
      </ul>
    </div>
  );
}
function WhyChoice() {
  useEffect(() => {
    let moveY = 5;
    let dir = false;
    window.$(window).scroll(function (e) {
      if (moveY == 5) {
        dir = false;
      }
      if (moveY == 0) {
        dir = true;
      }
      if (dir) {
        moveY++;
      } else {
        moveY--;
      }
      // window.$(".opacity-c").css({
      //   transform: `translateY(${-moveY}px)`
      // })
      // window.$(".stable-c").css({
      //   transform: `translateY(${-moveY}px)`
      // })
      // window.$(".credible-c").css({
      //   transform: `translateY(${-moveY}px)`
      // })
    });
  }, []);

  return (
    <div className="why-choice common-index">
      <div
        className="common-title alibaba-puhuitib aniview"
        data-av-animation="my-fadeInUp"
      >
        {intl.get("为什么选择USDB")}
      </div>
      <div className="choice-content aniview" data-av-animation="my-fadeIn">
        <div className="choice-l">
          <div className="sub-title alibaba-puhuiti∂çb">
            {intl.get("合规合法透明")}
          </div>
          <p>
            <img src={check} alt="" />
            {intl.get(
              "USDB的所有资产将全部记录在区块链账本中，不需要任何的财务美化和财务审计，USDB的财务数据将完全向所有持有者公开，以确保USDB具有100%可兑付能力"
            )}
          </p>
        </div>
        <div className="choice-r">
          <img className="opacity-pane" src={opacityPane} alt="" />
          <img className="opacity-c" src={opacityC} alt="" />
        </div>
      </div>
    </div>
  );
}
function StableValue() {
  return (
    <div
      className="stable-value common-index aniview"
      data-av-animation="my-fadeIn"
    >
      <div className="stable-content">
        <div className="stable-l">
          <img className="stable-pane" src={stablePane} alt="" />
          <img className="stable-c" src={stableC} alt="" />
        </div>
        <div className="stable-r">
          <div className="sub-title alibaba-puhuitib">
            {intl.get("价值稳定")}
          </div>
          <p>
            <img src={check} alt="" />
            {intl.get("1 USDB 恒等于 1 美元")}
          </p>
          <p>
            <img src={check} alt="" />
            {intl.get("每一枚USDB都对应了专项账户中的 1 美元")}
          </p>
          <p>
            <img src={check} alt="" />
            {intl.get("在任何时候，都可以保证USDB与美元1:1兑换")}
          </p>
        </div>
      </div>
    </div>
  );
}
function Advantage() {
  useEffect(() => {
    window.$("#advantage-box").mouseleave(function () {
      window.$("#freedom").addClass("center-box");
    });
    window.$("#advantage-box li").mouseenter(function () {
      window.$("#freedom").removeClass("center-box");
      window.$(this).addClass("center-box");
    });
    window.$("#advantage-box li").mouseleave(function () {
      window.$(this).removeClass("center-box");
    });
  }, []);
  return (
    <div className="advantage common-index">
      <ul id="advantage-box">
        <li>
          <img src={purchase} alt="" />
          <p className="alibaba-puhuitib">{intl.get("随时购买和赎回")}</p>
          <span>
            {intl.get(
              "您可以在全球任何地方以任何法币或数字货币随时购买USDB，更可以直接按市场汇率赎回任何你需要的法币或数字货币"
            )}
          </span>
        </li>
        <li id="freedom" className="center-box">
          <img src={freedom} alt="" />
          <p className="alibaba-puhuitib">{intl.get("全球转账，畅享自由")}</p>
          <span>
            {intl.get(
              "USDB的持有和转账不经过任何传统中心化金融机构，如银行，更无国界限制。未经本人同意，任何机构无法拦截你的转账和转移你的资产"
            )}
          </span>
        </li>
        <li>
          <img src={trans} alt="" />
          <p className="alibaba-puhuitib">{intl.get("转账迅速，手续费低")}</p>
          <span>
            {intl.get(
              "相比法币的传统电汇方式，USDB几分钟内就可以几乎免费的向全球任何地方进行转账，且不可逆转"
            )}
          </span>
        </li>
      </ul>
    </div>
  );
}
function MAdvantage() {
  const params = {
    loop: true,
    effect: "coverflow",
    slidesPerView: "auto",
    spaceBetween: -60,
    initialSlide: 1,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    navigation: {},
    coverflowEffect: {
      rotate: -20,
      stretch: 240,
      depth: 200,
      slideShadows: false,
    },
  };
  return (
    <div className="m-advantage common-index">
      <img className="slider-l" src={sliderL} alt="" />
      <img className="slider-r" src={sliderR} alt="" />
      <Swiper {...params}>
        <SwiperSlide>
          <div className="slider-item">
            <img src={purchase} alt="" />
            <p className="alibaba-puhuitib">{intl.get("随时购买和赎回")}</p>
            <span>
              {intl.get(
                "您可以在全球任何地方以任何法币或数字货币随时购买USDB，更可以直接按市场汇率赎回任何你需要的法币或数字货币"
              )}
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-item">
            <img src={freedom} alt="" />
            <p className="alibaba-puhuitib">{intl.get("全球转账，畅享自由")}</p>
            <span>
              {intl.get(
                "USDB的持有和转账不经过任何传统中心化金融机构，如银行，更无国界限制。未经本人同意，任何机构无法拦截你的转账和转移你的资产"
              )}
            </span>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-item">
            <img src={trans} alt="" />
            <p className="alibaba-puhuitib">{intl.get("转账迅速，手续费低")}</p>
            <span>
              {intl.get(
                "相比法币的传统电汇方式，USDB几分钟内就可以几乎免费的向全球任何地方进行转账，且不可逆转"
              )}
            </span>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
function Credible() {
  const [lang, setLang] = useState(() => {
    return localStorage.getItem("lang");
  });
  useEffect(() => {
    setLang(localStorage.getItem("lang"));
  }, []);
  return (
    <div
      className="credible common-index aniview"
      data-av-animation="my-fadeIn"
    >
      <div className="credible-content">
        <div className="credible-l">
          <div className="sub-title alibaba-puhuitib">
            {intl.get("安全可靠可信")}
          </div>
          <div className="line-text">
            <img className="pre-img" src={check} alt="" />
            {intl.get("USDB基于成熟的区块链公链-BSC实现，安全、可靠、可信")}
            {lang == "en-US" ? (
              <div className="btn-group">
                <div className="btn">
                  <a target="_blank" href="https://ethereum.org/en/">
                    {intl.get("了解BSC")}
                  </a>
                </div>
                {/* <div className="btn">
                  <a target="_blank" href="https://tron.network/index?lng=en">
                    {intl.get("了解波场(TRON)")}
                  </a>
                </div> */}
              </div>
            ) : (
              ""
            )}
            {lang == "zh-CN" ? (
              <div className="btn-group">
                <div className="btn">
                  <a target="_blank" href="https://ethereum.org/zh/">
                    {intl.get("了解BSC")}
                  </a>
                </div>
                {/* <div className="btn">
                  <a target="_blank" href="https://tron.network/index?lng=zh">
                    {intl.get("了解波场(TRON)")}
                  </a>
                </div> */}
              </div>
            ) : (
              ""
            )}
            {lang == "zh-TW" ? (
              <div className="btn-group">
                <div className="btn">
                  <a target="_blank" href="https://ethereum.org/zh-tw/">
                    {intl.get("了解BSC")}
                  </a>
                </div>
                {/* <div className="btn">
                  <a target="_blank" href="https://tron.network/index?lng=zh">
                    {intl.get("了解波场(TRON)")}
                  </a>
                </div> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="credible-r">
          <img className="credible-pane" src={crediblePane} alt="" />
          <img className="credible-c" src={credibleC} alt="" />
        </div>
      </div>
    </div>
  );
}
function Business() {
  return (
    <div className="business">
      <div className="business-content common-index">
        <div
          className="common-title alibaba-puhuitib aniview"
          data-av-animation="my-fadeInUp"
        >
          {intl.get("助力商业")}
        </div>
        <div className="business-text aniview" data-av-animation="my-fadeInUp">
          {intl.get(
            "使用USDB强大的API套件，自动、自定义并轻松快速的集成到您的业务，立刻拥有接收来自全世界的法币和数字货币付款的能力，不受国界限制，同时安全的将价值存储在USDB中，享受法币的稳定性和数字货币的自由"
          )}
        </div>
        <ul>
          <li className="aniview" data-av-animation="my-fadeIn">
            <img src={payApi} alt="" />
            <span className="alibaba-puhuitib">{intl.get("支付API")}</span>
          </li>
          <li className="aniview aniview-slow" data-av-animation="my-fadeIn">
            <img src={wallet} alt="" />
            <span className="alibaba-puhuitib">{intl.get("钱包API")}</span>
          </li>
          <li
            className="aniview aniview-reallyslow"
            data-av-animation="my-fadeIn"
          >
            <img src={market} alt="" />
            <span className="alibaba-puhuitib">{intl.get("市场API")}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
function GlobalCny() {
  const bg = {
    backgroundImage: `url(${map})`,
  };
  return (
    <div className="global-cny" style={bg}>
      <div
        className="global-cny-wrap common-index aniview"
        data-av-animation="my-fadeIn"
      >
        <div className="common-title alibaba-puhuitib-1">
          {intl.get("美元——全球公认的世界货币")}
        </div>
        <div className="global-cny-content">
          <div className="global-cny-l">
            <img className="global-cny-pane" src={globalCny} alt="" />
          </div>
          <div className="global-cny-r">
            <p>
              <img src={check} alt="" />
              {intl.get("美元发行由独立于政府之外的美联储严格控制，币值稳定")}
            </p>
            <p>
              <img src={check} alt="" />
              {intl.get(
                "美元是全球公认的世界货币，全球大多数的国家外汇储备以美元为主"
              )}
            </p>
            <p>
              <img src={check} alt="" />
              {intl.get(
                "USDB作为1:1对标美元的数字货币，依托区块链去中心化、匿名、安全、全球化、开放透明、不可撤销、不可篡改的优势，用户可切实享受到高效便捷服务"
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
function TokenInfo() {
  return (
    <div
      className="token-info common-index aniview footer-info"
      data-av-animation="my-fadeIn"
    >
      <div className="common-title alibaba-puhuitib">
        {intl.get("代币信息")}
      </div>
      <ul className="token-info-wrap">
        <li className="token-box">
          <dl>
            <dt>
              <img src={erc20} alt="" />
            </dt>
            <dd>
              <h3 className="alibaba-puhuitib">{intl.get("ERC-20代币")}</h3>
              {/* <p>{intl.get("利用以太坊的区块链")}</p> */}
            </dd>
          </dl>
          <ul className="token-info-des">
            {/* <li>
              <a
                target="_blank"
                href="https://etherscan.io/token/0x648e5c72316bb75a0a7171354c18f341aae150b8"
              >
                <img src={ercTokenInfo} alt="" />
                <span>{intl.get("Etherscan上的代币信息")}</span>
              </a>
            </li> */}
            <li>
              <a
                target="_blank"
                href="https://etherscan.io/address/0xd5FA89560A6a2CC4b25B74E3B8fB841761705e6c"
              >
                <img src={contractInfo} alt="" />
                <span>{intl.get("Etherscan上的合约信息")}</span>
              </a>
            </li>
            {/* <li>
              <a
                target="_blank"
                href="https://github.com/EchoCNY/Contract/tree/master/Erc20"
              >
                <img src={githubInfo} alt="" />
                <span>{intl.get("GitHub上的源代码")}</span>
              </a>
            </li> */}
          </ul>
        </li>

        <li className="token-box">
          <dl>
            <dt>
              <img src={bep20} alt="" />
            </dt>
            <dd>
              <h3 className="alibaba-puhuitib">{intl.get("BEP-20代币")}</h3>
              <p>{intl.get("利用BSC的区块链")}</p>
            </dd>
          </dl>
          <ul className="token-info-des">
            {/* <li>
              <img className="bep20-img" src={bep20} alt="" />
              <div className="bep20">
                <p className="bep20-title">{intl.get("BEP-20代币")}</p>
                <p>{intl.get("利用BSC")}</p>
              </div>
            </li> */}
            {/* <li>
              <a
                target="_blank"
                href="https://bscscan.com/token/0x8971c13bd1805e796545Fd7E1b5A385d4FC1E1Ff"
              >
                <img src={ercTokenInfo} alt="" />
                <span>{intl.get("BSCSCAN上的代币信息")}</span>
              </a>
            </li> */}
            <li>
              <a
                target="_blank"
                href="https://bscscan.com/address/0xFC3084116958676117f2c81E288e23B9c2feC497"
              >
                <img src={contractInfo} alt="" />
                <span>{intl.get("BSCSCAN上的合约信息")}</span>
              </a>
            </li>
            {/* <li>
              <a
                target="_blank"
                href="https://github.com/EchoCNY/Contract/tree/master/Erc20"
              >
                <img src={githubInfo} alt="" />
                <span>{intl.get("GitHub上的源代码")}</span>
              </a>
            </li> */}
          </ul>
        </li>
        <li className="token-box">
          <dl>
            <dt>
              <img src={trc20} alt="" />
            </dt>
            <dd>
              <h3 className="alibaba-puhuitib">{intl.get("TRC-20代币")}</h3>
              {/* <p>{intl.get("利用波场的区块链")}</p> */}
            </dd>
          </dl>
          <ul className="token-info-des">
            {/* <li>
              <a
                target="_blank"
                href="https://tronscan.org/#/contract/TS8u3am1vPX5Zkc6CAu76c8P1GLJz6z9XD"
              >
                <img src={trcTokenInfo} alt="" />
                <span>{intl.get("TRONSCAN上的代币信息")}</span>
              </a>
            </li> */}
            <li>
              <a
                target="_blank"
                href=" https://tronscan.org/#/contract/TS8u3am1vPX5Zkc6CAu76c8P1GLJz6z9XD"
              >
                <img src={contractInfo} alt="" />
                <span>{intl.get("TRONSCAN上的合约信息")}</span>
              </a>
            </li>
            {/* <li>
              <a
                target="_blank"
                href="https://github.com/EchoCNY/Contract/tree/master/Trc20"
              >
                <img src={githubInfo} alt="" />
                <span>{intl.get("GitHub上的源代码")}</span>
              </a>
            </li> */}
          </ul>
        </li>
      </ul>
    </div>
  );
}
function Footer() {
  return (
    <div className="footer">
      <div className="footer-c common-index">
        <div className="logo">
          <img src={footerLogo} alt="" />
        </div>
        <div className="copyright">
          Copyright &copy; 2021 USDB, All Rights Reserved
        </div>
        <div className="contact-us">
          <div className="contact-btn">{intl.get("联系我们")}</div>
          <ul className="contact-info">
            <li>
              <a target="_blank" href="https://t.me/USDB_01">
                <img src={telegram} alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
